const markdownIt = require("markdown-it")({});

const richTextUnitToHtml = (units) => {
  let html = "";
  for (let unit of units) {
    if (unit.unitType == 1) {
      // md
      html = html + markdownIt.render(unit.markDown.content);
    } else if (unit.unitType == 2) {
      // html
      html = html + unit.html.content;
    }
  }
  html = innerLatexImg(html);
  return html;
};

const removeHtmlTag = (cont) => {
  const tag = "</p>";
  cont = cont.replace("<p>", "");
  const lastIndex = cont.lastIndexOf("</p>");
  cont = cont.slice(0, lastIndex) + "" + cont.slice(lastIndex + tag.length);
  return cont;
};

const innerLatexImg = (html) => {
  const token = "$$";

  for (let i = 0, loop = 0; i < html.length && loop < 10; loop++) {
    let beginIdx = html.indexOf(token, i);
    let endIdx = -1;
    if (beginIdx >= 0) {
      endIdx = html.indexOf(token, beginIdx + token.length);
    }
    if (beginIdx >= 0 && endIdx >= 0) {
      let formula = html.substring(beginIdx + token.length, endIdx);
      formula = encodeURIComponent(formula);
      let latexImgUrl = `<div class='latex-block'><img src='//latex-cdn.aida101.com/latex/get?tex=${formula}&size=20' /><div>`;
      html = html.substring(0, beginIdx) + latexImgUrl + html.substring(endIdx + token.length);
      i = endIdx + token.length;
    } else {
      break;
    }
  }
  const inlineToken = "$";
  for (let i = 0, loop = 0; i < html.length && loop < 10; loop++) {
    let beginIdx = html.indexOf(inlineToken, i);
    let endIdx = -1;
    if (beginIdx >= 0) {
      endIdx = html.indexOf(inlineToken, beginIdx + inlineToken.length);
    }
    if (beginIdx >= 0 && endIdx >= 0) {
      let formula = html.substring(beginIdx + inlineToken.length, endIdx);
      formula = encodeURIComponent(formula);
      let latexImgUrl = `<img class='latex-inline' src='//latex-cdn.aida101.com/latex/get?tex=${formula}&size=16' />`;
      html = html.substring(0, beginIdx) + latexImgUrl + html.substring(endIdx + inlineToken.length);
      i = endIdx + inlineToken.length;
    } else {
      break;
    }
  }

  return html;
};

const stringMdToHtml = (strList) => {
  let html = "";
  let tmpList = [];
  //return String.fromCharCode(97 + val);
  for (let i = 0; i < strList.length; i++) {
    tmpList.push("<span>" + String.fromCharCode(65 + i) + ". " + strList[i] + "</span>");
  }
  html = tmpList.join("<br />");
  // for (let unit of strList) {
  //   html = html + this.$markdownIt.render(unit);
  // }    let showAns = tmpList.join(",");
  html = innerLatexImg(html);
  return html;
};

export const mdUtil = {
  stringMdToHtml: stringMdToHtml,
  richTextUnitToHtml: richTextUnitToHtml,
  innerLatexImg: innerLatexImg,
  removeHtmlTag: removeHtmlTag,
};
